import React, { Fragment, MutableRefObject, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Container,
  OptionListContainer,
  ContainerItem,
  Circle,
  ItemTitle,
  TimeContainer,
  TimeTitle,
  Time,
  ContainerStatus,
  ContainerButtonRefresh,
  StatusItem,
} from "./styles";
import { BottomMarkingTime, NewMarkingButton } from "../Marking/styles";
import { v4 } from "uuid";
import { formatYmd } from "../../../utils/format";
import { transcription } from "../../../services/app/transcription";
import { useQuery } from "../../../utils/get";
import { useToast } from "../../../hooks/toast";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { FiRefreshCw } from "react-icons/fi";
import { LogOperation } from "../../../utils/entities/logOperation";
import { editorInit } from "../../../services/utils/tinyConfiguration";
import { usePermissions } from "../../../hooks/permission";
import { FORM_EXPORT_FILES } from "../../../utils/entities/permission";
import { ContainerTemplate } from "../styles";
import { DropdownGlobal } from "../../../components/DropdownGlobal";
import { ICustomerTemplate } from "../../../pages/app/CustomerTemplate";
import { customerTemplateService } from "../../../services/customerTemplate";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export interface ITranscriptionComponent {
  manualTranscriptionRef: MutableRefObject<any>;
  transcriptions: Array<any>;
  marking: any;
  file: any;
  modelList: Array<ICustomerTemplate>;
  getTranscription: () => Promise<unknown>;
}

const optionList = [
  { id: 1, value: "Transcrição manual", type: "manual" },
  { id: 2, value: "Transcrição automática", type: "automatico" },
];

export const TranscriptionComponent: React.FC<ITranscriptionComponent> = ({
  manualTranscriptionRef,
  transcriptions,
  marking,
  file,
  getTranscription,
  modelList,
}) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>("");
  const [selectTemplate, setSelectTemplate] = useState<any>();
  const [tinyText, setTinyText] = useState<string>("");
  const [showModelInput, setShowModelInput] = useState<boolean>(false);
  const [autoTranscription, setAutoTranscription] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<number>(1);
  const permissions = usePermissions(FORM_EXPORT_FILES.HEARING_FILE);
  const user = getValueFromlocalStorage("@Kenta:user");
  const query = useQuery();

  const hearingId = query.get("hearingId");

  const passedUser = user ? JSON.parse(user) : null;

  const startTranscription = async () => {
    if (transcriptions && transcriptions[0] && transcriptions[0].id) {
      if (
        manualTranscriptionRef.current &&
        manualTranscriptionRef.current.getContent()
      ) {
        if (transcriptions[0].autoTranscription) {
          await transcription.patchDisableAutoTranscription(
            transcriptions[0].id
          );
        }

        const { success } = await transcription.patch({
          textPlain: manualTranscriptionRef.current.getContent(),
          id: transcriptions[0].id,
        });

        if (selectTemplate) {
          const { success: successTemplate } =
            await transcription.patchTemplate({
              customerTemplateId: selectTemplate.id,
              id: transcriptions[0].id,
            });
        }

        if (success) {
          addToast({
            type: "success",
            title: "Transcrição atualizada.",
            description: "Sua transcrição foi atualizada com sucesso.",
          });

          if (transcriptions[0].autoTranscription) {
            await getTranscription();
          }
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Transcrição não atualizada",
            description: "A transcrição não pode ser atualizada",
          });
        }
      }
      return;
    }

    if (file.id) {
      if (selected === "automatico" && passedUser) {
        if (!selectTemplate) {
          return addToast({
            type: "warning",
            title: "Aviso",
            description:
              "Para criar uma transcrição automática é necessário selecionar um modelo",
          });
        }

        const data = {
          id: 0,
          guid: v4(),
          hearingFileId: file ? file.id : 0,
          userId: passedUser.id,
          customerTemplateId: selectTemplate ? selectTemplate.id : 0,
          title: `Transcrição da oitiva nº: ${query.get("hearingId")}`, // audiencia
          autoTranscription: true,
          createIn: formatYmd(new Date()), // Remover
          updateIn: formatYmd(new Date()), // Remover
          sendEmail: true,
          active: true,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        dispatch(showLoading());
        await transcription.post("/HearingTranscription/add", data);
        setShowModelInput(false);
        await getTranscription();
        dispatch(hideLoading());
        addToast({
          type: "success",
          title: "Transcrição automatica iniciada.",
          description: "Sua transcrição foi iniciada com sucesso.",
        });
      }

      if (selected === "manual" && passedUser) {
        const data = {
          id: 0,
          guid: v4(),
          hearingFileId: file ? file.id : 0,
          userId: passedUser.id,
          customerTemplateId: 0,
          title: `Transcrição da oitiva nº: ${query.get("hearingId")}`,
          autoTranscription: false,
          createIn: formatYmd(new Date()),
          updateIn: formatYmd(new Date()),
          status: 6,
          sendEmail: false,
          active: true,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        dispatch(showLoading());
        await transcription.post("/HearingTranscription/add", data);
        await getTranscription();
        dispatch(hideLoading());
        addToast({
          type: "success",
          title: "Transcrição manual criada.",
          description: "Sua transcrição foi criada com sucesso.",
        });
      }
    } else {
      dispatch(hideLoading());
      addToast({
        type: "warning",
        title: "Transcrição não criada",
        description:
          "A transcrição não pode ser criada pois não existe uma video para transcrever.",
      });
    }
  };

  const TranscriptionStatus = (status: number) => {
    switch (status) {
      case 0:
        return <StatusItem>Na fila</StatusItem>;
      case 1:
        return <StatusItem>Carregando vídeo</StatusItem>;
      case 2:
        return <StatusItem>Extraindo áudio</StatusItem>;
      case 3:
        return <StatusItem>Transferindo áudio</StatusItem>;
      case 4:
        return <StatusItem>Transcrevendo áudio</StatusItem>;
      case 5:
        return <StatusItem>Encerrando</StatusItem>;
      case 6:
        return <StatusItem>Encerrado</StatusItem>;
      case 7:
        return <StatusItem>Cancelado</StatusItem>;
      case 8:
        return <StatusItem>Erro ao carregar vídeo</StatusItem>;
      case 9:
        return <StatusItem>Erro ao extrair áudio</StatusItem>;
      case 10:
        return <StatusItem>Erro ao transferir áudio</StatusItem>;
      case 11:
        return <StatusItem>Erro ao transcrever áudio</StatusItem>;
      case 12:
        return <StatusItem>Erro ao debitar crédito</StatusItem>;
      case 13:
        return <StatusItem>Erro de processamento</StatusItem>;
      case 14:
        return <StatusItem>Saldo insuficiente</StatusItem>;
      default:
        return <StatusItem>Na fila</StatusItem>;
    }
  };

  useEffect(() => {
    (async () => {
      await getTranscription();
    })();
  }, []);

  useEffect(() => {
    if (transcriptions && transcriptions.length) {
      setTinyText(transcriptions[0].textPlain);
    }
  }, [transcriptions]);

  const selectedModel =
    transcriptions && transcriptions[0] && modelList.length
      ? modelList.find((x) => x.id === transcriptions[0].customerTemplateId)
      : { name: "", template: "" };

  useEffect(() => {
    (async () => {
      if (selectTemplate && selectTemplate.template) {
        const { body, message, success } =
          await customerTemplateService.SetKeywords({
            hearingId: Number(hearingId),
            customerTemplateId:
              selectTemplate && selectTemplate.id ? selectTemplate.id : 0,
          });

        setTinyText(body);
      }
    })();
  }, [selectTemplate, hearingId]);

  useEffect(() => {
    (async () => {
      await getTranscription();
    })();
  }, [reloadPage]);

  const canReloadAutoTranscription = () => {
    if (transcriptions[0] !== undefined) {
      if (transcriptions.length || autoTranscription) {
        let statusVerify = transcriptions[0].status;
        if (statusVerify >= 7 && statusVerify <= 13) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const handleReloadAutoTranscription = async () => {
    const { success } = await transcription.patchGeneric(
      "0",
      transcriptions[0].id.toString(),
      "status"
    );

    setReloadPage(reloadPage + 1);

    if (success) {
      addToast({
        type: "success",
        title: "Transcrição automatica iniciada.",
        description: "Sua transcrição foi iniciada com sucesso.",
      });
    } else {
      addToast({
        type: "error",
        title: "Transcrição não criada",
        description: "erro inesperado",
      });
    }
  };

  return (
    <>
      {showModelInput && (
        <ContainerTemplate>
          <div style={{ paddingLeft: "20px" }}>
            <DropdownGlobal
              labelName="Modelo"
              options={modelList as any}
              defaultValue={selectedModel ? selectedModel.name : ""}
              showDefaultValue={!!selectedModel && !!selectedModel.name}
              width="320px"
              setSelected={setSelectTemplate}
              wantAnObject
            />
          </div>
        </ContainerTemplate>
      )}
      <Container
        hasModel={showModelInput}
        hasTranscription={!!transcriptions.length}
      >
        {transcriptions.length || autoTranscription ? (
          transcriptions[0].status !== 6 ? (
            <>
              <ContainerButtonRefresh onClick={getTranscription}>
                <FiRefreshCw />
              </ContainerButtonRefresh>
              <ContainerStatus>
                {TranscriptionStatus(
                  Number(transcriptions ? transcriptions[0].status : 0)
                )}
              </ContainerStatus>
            </>
          ) : (
            <Editor
              ref={manualTranscriptionRef}
              apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
              onInit={(evt, editor) => {
                setShowModelInput(true);

                return (manualTranscriptionRef.current = editor);
              }}
              init={editorInit(permissions.export)}
              onReset={() =>
                setTimeout(() => {
                  return true;
                }, 1000)
              }
              initialValue={
                transcriptions &&
                transcriptions.length &&
                transcriptions[0] &&
                transcriptions[0].textPlain
                  ? transcriptions[0].textPlain
                  : ""
              }
              value={tinyText}
              onEditorChange={(e) => {
                setTinyText(e);
              }}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
          )
        ) : (
          <>
            <OptionListContainer>
              {optionList.map((item, index) => (
                <ContainerItem
                  key={item.id}
                  onClick={() => {
                    setSelected(item.type === selected ? "" : item.type);
                    if (item.type === "automatico") setShowModelInput(true);
                    else setShowModelInput(false);
                  }}
                >
                  <Circle selected={selected === item.type} />
                  <ItemTitle>{item.value}</ItemTitle>
                </ContainerItem>
              ))}
            </OptionListContainer>
            <TimeContainer>
              <TimeTitle>Tempo total: </TimeTitle>
              <Time>
                {marking.playerState && marking.playerState.duration
                  ? marking.playerState.duration
                  : "00:00"}
              </Time>
            </TimeContainer>
          </>
        )}
      </Container>

      <BottomMarkingTime>
        <NewMarkingButton
          width={91}
          onClick={
            canReloadAutoTranscription()
              ? handleReloadAutoTranscription
              : startTranscription
          }
          disabled={
            canReloadAutoTranscription()
              ? false
              : transcriptions &&
                transcriptions[0] &&
                transcriptions[0].id &&
                transcriptions[0].status !== 6
          }
        >
          <strong>
            {canReloadAutoTranscription()
              ? "Transcrever"
              : !transcriptions.length || autoTranscription
              ? "Iniciar"
              : "Salvar"}
          </strong>
        </NewMarkingButton>
      </BottomMarkingTime>
    </>
  );
};
