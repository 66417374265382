import React, {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { storageBaseUrl } from "../../services/api";
import { HearingService } from "../../services/hearing";
import { setControls } from "../../store/modules/playercontrol/actions";
import ReactLoading from "react-loading";
import { useQuery } from "../../utils/get";
import PlayerControl from "../PlayerControl";

import {
  Container,
  VideoContainer,
  VideoPlayerComponent,
  LoadingContainer,
  VideoText,
} from "./styles";

function usePlayerState($videoPlayer: MutableRefObject<HTMLVideoElement>) {
  const [playerState, setPlayerState] = useState({
    playing: false,
    progress: 0,
    currentTime: "00:00",
    duration: "00:00",
    secondsCurrentTime: 0,
    secondsDuration: 0,
    volume: 1,
    playbackRate: 1,
    marking: null,
  });

  function toggleVideoPlay() {
    setPlayerState({
      ...playerState,
      playing: !playerState.playing,
    });

    if ($videoPlayer.current && playerState.playing) {
      $videoPlayer.current.pause();
    } else {
      $videoPlayer.current.play();
    }
  }

  function toggleVideoPause() {
    setPlayerState({
      ...playerState,
      playing: false,
    });

    if ($videoPlayer.current) {
      $videoPlayer.current.pause();
    }
  }

  function toggleVideoProgress(currentTime: number, percent: number) {
    const totalSeconds = $videoPlayer.current.duration;
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    const totalSecondsCurrent = currentTime;
    const totalMinutesCurrent = Math.floor(totalSecondsCurrent / 60);

    const secondsCurrent = Math.floor(totalSecondsCurrent % 60);
    const minutesCurrent = totalMinutesCurrent % 60;
    const hoursCurrent = Math.floor(totalMinutesCurrent / 60);

    setPlayerState({
      ...playerState,
      progress: percent,
      secondsCurrentTime: currentTime - 0.05,
      secondsDuration: totalSeconds,
      duration: `${hours === 0 ? "" : hours < 10 ? `0${hours}:` : `${hours}:`}${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`,
      currentTime: `${hoursCurrent === 0
        ? ""
        : hoursCurrent < 10
          ? `0${hoursCurrent}:`
          : `${hoursCurrent}:`
        }${minutesCurrent < 10 ? "0" + minutesCurrent : minutesCurrent}:${secondsCurrent < 10 ? "0" + secondsCurrent : secondsCurrent
        }`,
    });

    if (percent === 100) {
      toggleVideoPlay();
      $videoPlayer.current.currentTime = 0;
    }
  }

  function toggleFullscreen() {
    $videoPlayer.current.requestFullscreen();
  }

  function toggleChangeProgress(percent: number) {
    const currentTime = ($videoPlayer.current.duration / 100) * percent;

    $videoPlayer.current.currentTime = currentTime;

    setPlayerState({
      ...playerState,
      secondsCurrentTime: currentTime,
    });
  }

  function toggleFiveSeconds(type: "advance" | "back") {
    let timeDefault: number = 5;
    let timeConfig = localStorage.getItem("GlobalTimeBackNext");

    if (timeConfig !== null) {
      timeDefault = parseInt(timeConfig);
    }

    switch (type) {
      case "advance":
        $videoPlayer.current.currentTime += timeDefault;
        break;
      case "back":
        $videoPlayer.current.currentTime -= timeDefault;
        break;
    }
  }

  function toggleVolume(volume: number) {
    setPlayerState({ ...playerState, volume });
    $videoPlayer.current.volume = volume;
  }

  function toggleRate(rate: number) {
    $videoPlayer.current.playbackRate = rate;
    setPlayerState({ ...playerState, playbackRate: rate });
  }

  function toggleMarkingTime(marking: number) {
    $videoPlayer.current.currentTime = marking;
  }

  return {
    playerState,
    toggleVideoPlay,
    toggleVideoPause,
    toggleVideoProgress,
    toggleFullscreen,
    toggleChangeProgress,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
    toggleMarkingTime,
  };
}

export interface IMarkingType {
  toggleMarkingTime: (time: number) => unknown;
  toggleRate: (rate: number) => unknown;
  toggleVideoPlay: () => unknown;
  hearingRecord: any;
  file?: any;
  playerState: {
    playing: boolean;
    progress: number;
    currentTime: string;
    duration: string;
    offset1: string;
    offset2: string;
    secondsCurrentTime: number;
    secondsDuration: number;
    volume: number;
    playbackRate: number;
    marking: any;
  };
}

type IVideoPlayer = {
  setMarking: any;
  marking?: IMarkingType;
  videoUrl?: string;
  modalIsOpen: boolean;
  openMarkingModal: boolean;
  setCurrenctTime?: (time: number) => unknown;
  isBegining: boolean;
};

export const VideoPlayer: React.FC<IVideoPlayer> = ({
  marking,
  setMarking,
  modalIsOpen,
  openMarkingModal,
  isBegining,
}) => {
  const $videoPlayer = useRef<any>(null);
  const [controls, setControlls] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadAgain, setloadAgain] = useState(false);
  const [hearingRecordItem, setHearingRecordItem] = useState(false);
  const [hearingVideo, setHearingVideo] = useState("");
  const { policeUnit } = useSelector((state: any) => state.policeUnit);

  const query = useQuery();
  const hearingId = query.get("hearingId");

  const getHearingVideo = async () => {
    if (!loadAgain) {
      setLoading(true);

      const unit = policeUnit;

      const container =
        unit && unit.containerName ? unit.containerName : "santacatarina";

      if (hearingId) {
        const { data, status } = await HearingService.getHearingById(
          Number(hearingId)
        );

        if (status && data) {
          const hearingRecord = data.find((x: any) => {
            return x.type === 1;
          });

          if (hearingRecord) {
            setMarking({ ...marking, hearingRecord });
            setHearingRecordItem(hearingRecord);

            setHearingVideo(
              `${storageBaseUrl}/${container}/${hearingRecord.filename.replace(
                /\\/gi,
                "/"
              )}`
            );
          }
        } else {
          setLoading(false);
        }

        if (!status) {
          setLoading(false);
        }
      }
    }
  };

  const canPlayVideo = () => {
    setLoading(false);
    setloadAgain(true);
  };

  const toggleFullScreenRequest = () => {
    setControlls(true);
    toggleFullscreen();
  };

  useEffect(() => {
    (async () => {
      await getHearingVideo();
      // dispatch(setControls(marking));
    })();

    document.addEventListener("keydown", () => setControlls(false), false);
    document.addEventListener("fullscreenchange", () => setControlls(false));
  }, []);

  // offset
  useEffect(() => {
    if (isBegining) {
      setMarking({
        toggleMarkingTime,
        toggleRate,
        toggleVideoPlay,
        playerState: {
          ...playerState,
          offset1: isBegining
            ? playerState.currentTime
            : marking?.playerState.offset1,
          offset2: !isBegining ? playerState.currentTime : "00:00",
        },
        hearingRecord: hearingRecordItem,
      });
    }
  }, [isBegining])

  //=================================SHORTCUTS==================================

  let startPause: boolean = true;

  interface shortcut {
    shortcut: string;
    method: string;
  }

  let listShortcuts: Array<shortcut> = [];

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.ctrlKey === true) {
        event.preventDefault();
        let firstShortcut = "Ctrl";
        if (event.key !== event.ctrlKey) {
          let secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          let findSC = listShortcuts.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );

          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else if (event.shiftKey === true && !modalIsOpen && !openMarkingModal) {
        event.preventDefault();
        let firstShortcut = "Shift";
        if (event.key !== event.shiftKey) {
          let secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          let findSC = listShortcuts.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );
          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else if (event.altKey === true) {
        event.preventDefault();
        let firstShortcut = "Alt";
        event.preventDefault();
        if (event.key !== event.altKey) {
          event.preventDefault();
          let secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          let findSC = listShortcuts.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );
          event.preventDefault();
          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else {
        if (!modalIsOpen) {
          let secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          let findSC = listShortcuts.find((x) => x.shortcut === secondShortcut);

          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      }
    }, [modalIsOpen, openMarkingModal]
  );

  useEffect(() => {
    let playStopShortcuts: shortcut = {
      method: "GlobalPlayStop",
      shortcut: localStorage.getItem("GlobalPlayStop") ?? "",
    };

    let actionNextTimeShortcuts: shortcut = {
      method: "GlobalActionNextTime",
      shortcut: localStorage.getItem("GlobalActionNextTime") ?? "",
    };

    let actionBackTimeShortcuts: shortcut = {
      method: "GlobalActionBackTime",
      shortcut: localStorage.getItem("GlobalActionBackTime") ?? "",
    };

    let slowSpeedShortcuts: shortcut = {
      method: "GlobalSlowSpeed",
      shortcut: localStorage.getItem("GlobalSlowSpeed") ?? "",
    };

    let fastSpeedShortcuts: shortcut = {
      method: "GlobalFastSpeed",
      shortcut: localStorage.getItem("GlobalFastSpeed") ?? "",
    };

    listShortcuts.push(playStopShortcuts);
    listShortcuts.push(actionNextTimeShortcuts);
    listShortcuts.push(actionBackTimeShortcuts);
    listShortcuts.push(slowSpeedShortcuts);
    listShortcuts.push(fastSpeedShortcuts);

    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress, startPause, modalIsOpen, openMarkingModal]);

  function executerShortcut(method: string) {
    if (method === "GlobalPlayStop") {
      handlePlayStop();
    } else if (method === "GlobalActionNextTime") {
      handleActionNextTime();
    } else if (method === "GlobalActionBackTime") {
      handleActionBackTime();
    } else if (method === "GlobalSlowSpeed") {
      handleSlowSpeed();
    } else if (method === "GlobalFastSpeed") {
      handleFastSpeed();
    }
    return;
  }

  const handlePlayStop = () => {
    if (startPause) {
      toggleVideoPlay();
    } else {
      toggleVideoPause();
    }
    startPause = !startPause;
  };

  const handleActionBackTime = () => {
    toggleFiveSeconds("back");
  };

  const handleActionNextTime = () => {
    toggleFiveSeconds("advance");
  };

  const handleSlowSpeed = () => {
    let speedCurrente = $videoPlayer.current.playbackRate;
    if (speedCurrente === 0.5) {
      return;
    }
    toggleRate(speedCurrente - 0.25);
  };

  const handleFastSpeed = () => {
    let speedCurrente = $videoPlayer.current.playbackRate;
    if (speedCurrente === 2) {
      return;
    }
    toggleRate(speedCurrente + 0.25);
  };

  //================================================================================

  const {
    playerState,
    toggleVideoProgress,
    toggleVideoPlay,
    toggleVideoPause,
    toggleFullscreen,
    toggleChangeProgress,
    toggleMarkingTime,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
  } = usePlayerState($videoPlayer);

  useEffect(() => {
    setMarking({
      toggleMarkingTime,
      toggleRate,
      toggleVideoPlay,
      playerState: {
        ...playerState,
        offset1: isBegining
          ? playerState.currentTime
          : marking?.playerState.offset1,
        offset2: !isBegining ? playerState.currentTime : "00:00",
      },
      hearingRecord: hearingRecordItem,
    });
  }, [$videoPlayer, playerState, playerState.duration, hearingRecordItem]);

  return (
    <Container>
      <VideoContainer>
        {hearingVideo && (
          <VideoPlayerComponent
            ref={$videoPlayer}
            onCanPlay={canPlayVideo}
            onTimeUpdate={() => {
              const currentPorcetage =
                ($videoPlayer.current.currentTime /
                  $videoPlayer.current.duration) *
                100;
              toggleVideoProgress(
                $videoPlayer.current.currentTime,
                currentPorcetage
              );
            }}
            controls={controls}
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            preload="auto"
          >
            <source src={hearingVideo} type="video/mp4" />
          </VideoPlayerComponent>
        )}
        <PlayerControl
          disabled={loading}
          playerState={playerState}
          toggleChangeProgress={toggleChangeProgress}
          toggleVideoPlay={toggleVideoPlay}
          toggleFullscreen={toggleFullScreenRequest}
          toggleFiveSeconds={toggleFiveSeconds}
          toggleVolume={toggleVolume}
          toggleRate={toggleRate}
        />
        {hearingVideo && loading ? (
          <LoadingContainer>
            <ReactLoading type="spin" color="#fff" width={100} height={100} />
            <VideoText>Carregando...</VideoText>
          </LoadingContainer>
        ) : (
          !hearingVideo && <VideoText>Nenhum video para exibir</VideoText>
        )}
      </VideoContainer>
    </Container>
  );
};
