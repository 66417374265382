import React, { MutableRefObject, useEffect, useState } from "react";
import HearingInformation from "./HearingInformation";
import { MarkingContent } from "./Marking";
import { TranscriptionComponent } from "./Transcription";
import { AtaComponent } from "./Ata";
import { useQuery } from "../../utils/get";
import { customerTemplateService } from "../../services/customerTemplate";
import { LogOperation } from "../../utils/entities/logOperation";
import { TabsComponent } from "../../components/Tabs";
import { Summary } from "./Summary";

type IHearingDetailsSidebar = {
  marking: any;
  manualTranscriptionRef: MutableRefObject<any>;
  setOpenModal: (bool: boolean) => unknown;
  onEditMarking: (marking: any) => unknown;
  onDeleteMarking: (marking: any) => unknown;
  getTranscription: () => Promise<unknown>;
  procedureItem: any;
  dataMarking: Array<any>;
  currentIndex: number;
  setCurrentIndex: (number: number) => unknown;
  file?: any;
  transcription: Array<any>;
  openModalFile: boolean;
  setOpenModalFile: (e: boolean) => unknown;
  isBeginin: boolean;
  processCuts: () => void;
  onClickRefreshStatusCuts: () => void;
};

const HearingDetailsSidebar: React.FC<IHearingDetailsSidebar> = ({
  marking,
  onDeleteMarking,
  onEditMarking,
  procedureItem,
  setOpenModal,
  dataMarking,
  currentIndex,
  setCurrentIndex,
  manualTranscriptionRef,
  transcription,
  getTranscription,
  openModalFile,
  setOpenModalFile,
  isBeginin,
  processCuts,
  onClickRefreshStatusCuts,
}) => {
  const query = useQuery();
  const [selectedMenu, setSelectedMenu] = useState(query.get("startOn") || "1");
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState();

  useEffect(() => {
    if (selectedMenu === "3" || selectedMenu === "4") {
      loadingModel(selectedMenu === "3" ? 2 : 1);
    }
  }, [selectedMenu]);

  const loadingModel = async (type: number) => {
    try {
      setModelList([]);
      const { body, success } =
        await customerTemplateService.getAllByCustomerId({
          active: true,
          customerId: LogOperation.getCustomerId(),
          type: type,
        });

      if (success) {
        const list = body.map((item: any) => ({ ...item, name: item.title }));

        setModelList(list);
      }
    } catch (error) {}
  };

  const template: any = selectedModel
    ? modelList.find((x: any) => x.id === selectedModel)
    : "";

  const tabsList = [
    {
      id: "1",
      name: "Informações",
      component: (
        <HearingInformation
          procedureItem={procedureItem}
          openModalFile={openModalFile}
          setOpenModalFile={setOpenModalFile}
        />
      ),
    },
    {
      id: "2",
      name: "Marcações",
      component: (
        <MarkingContent
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onDeleteMarking={onDeleteMarking}
          onEditMarking={onEditMarking}
          data={dataMarking}
          setOpenModal={setOpenModal}
          marking={marking}
          isBeginin={isBeginin}
          processCuts={processCuts}
          onClickRefreshStatusCuts={onClickRefreshStatusCuts}
        />
      ),
    },
    {
      id: "3",
      name: "Transcrições",
      component: (
        <TranscriptionComponent
          file={marking.hearingRecord}
          marking={marking}
          transcriptions={transcription}
          manualTranscriptionRef={manualTranscriptionRef}
          getTranscription={getTranscription}
          modelList={modelList}
        />
      ),
    },
    {
      id: "5",
      name: "Resumo",
      component: (
        <Summary
          buttonFunction={() => {}}
          buttonTitle=""
          status={null}
          disabledButton={false}
          getTranscription={getTranscription}
          transcriptions={transcription}
        />
      ),
    },
    {
      id: "4",
      name: "Ata",
      component: (
        <AtaComponent
          modelList={modelList}
          setSelectedModel={setSelectedModel}
          template={template}
        />
      ),
    },
   
  ];

  return (
    <TabsComponent
      list={tabsList}
      selectedMenu={selectedMenu}
      setSelectedMenu={(e: string) => {
        setSelectedMenu(e);
      }}
    />
  );
};

export default HearingDetailsSidebar;
