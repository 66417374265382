/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import storeRedux from "./store";
import AppProvider from './hooks';
import { CookiesProvider } from 'react-cookie';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={storeRedux}>
        <AppProvider>
          <App />
        </AppProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);
