import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 30%;
  min-width: 360px;
  height: 81.8vh;
  background: #fff;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
`;

export const HeaderContainer = styled.nav`
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
    padding: 0 20px;
    width: 100% -20px;
    height: 64px;
    background: white;
    text-decoration: none;
    border-bottom: 1px solid #cbcbcb;
  }
`;

export const ItemListMenu = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
    `}

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #e98d0b;
    margin-top: 5px;
  }

  //border-bottom: 4px solid #ffffff;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  //background-color: red;
`;

export const Content = styled.div`
  width: 100%;
  height: 70vh;
  //background: red;
`;

export const ContainerTemplate = styled.div`
  width: 100%;
`;

//****************** */

export const TabContainer = styled.div`
  width: 30%;
  min-width: 360px;
  height: 60px;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const TabContainerTextAndLine = styled.div`
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  margin-bottom: 5px;

  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
      height: 56px;
    `}

  &:hover {    
    ${(props: { selected?: boolean }) =>
    props.selected === true ?
    css`
      border-bottom: 4px solid #e98d0b;      
    `
    : 
    css`
      border-bottom: 1px solid #e98d0b;
    `}
  }
`;

export const TabText = styled.div`
  text-align: center;
  /* 14 Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
`;

