import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Router } from "./routers";
import GlobalStyle from "./global/styles";
import AppProvider from "./hooks";
import { CookiesProvider } from "react-cookie";
import "react-tooltip/dist/react-tooltip.css";
import { LoadingToast } from "./components/LoadingToast";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <LoadingToast />
      <Router />
    </BrowserRouter>
  );
}

export default App;
