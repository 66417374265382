import { useState, useEffect } from "react";
import {
  Container,
  ContainerBar,
  ContainerDescription,
  ContainerTitle,
} from "./style";

import { Typhography } from "../Typhography";
import { InputAuth } from "../Login/Input";
import { LogOperation } from "../../utils/entities/logOperation";
import { useToast } from "../../hooks/toast";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { useDispatch, useSelector } from "react-redux";
import { SHA1 as sha1 } from "crypto-js";
import BarComponent from "../StrongBar";
import { PasswordService } from "../../services/password";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../../utils/sessionStorageEncrypt";

type typesInput = "email" | "password" | "text" | undefined;
type strlvl = "weak" | "medium" | "strong";

interface IChangePassword {
  isLoged: boolean;
  passwordOld: string;
  setPasswordOld: (e: string) => any;
  password: string;
  setPassword: (e: string) => any;
  passwordConfirm: string;
  setPasswordConfirm: (e: string) => any;
  submitNewPassword: boolean;
  setSubmitNewPassword: (e: any) => any;
}

export const ChangePassword = (props: IChangePassword) => {
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strongLvl, setStrongLvl] = useState<strlvl>("weak");
  const [passwordType, setPasswordType] = useState<typesInput>("password");

  let memoryPass = getValueFromlocalStorage("@Kenta:password");

  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    if (props.submitNewPassword && props.passwordOld) {
      submitNewPassword();
    }
    props.setSubmitNewPassword(false);
  }, [props.submitNewPassword]);

  function isStrongPassword(password: string): boolean {
    // Check if password is at least 8 characters long
    if (password.length < 8) {
      setStrongLvl("weak");
      return false;
    }

    // Check if password contains at least one lowercase letter, one uppercase letter, one digit, and one special character
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()\-_=+[\]{};':"\\|,.<>/?]/;

    if (
      !lowercaseRegex.test(password) ||
      !uppercaseRegex.test(password) ||
      !digitRegex.test(password) ||
      !specialCharRegex.test(password)
    ) {
      setStrongLvl("medium");
      return false;
    }
    setStrongLvl("strong");
    // Password meets all criteria for a strong password
    return true;
  }

  async function submitNewPassword() {
    let passDigited = sha1(props.passwordOld).toString().toUpperCase();
    let passDigitedConfirm = sha1(props.passwordConfirm)
      .toString()
      .toUpperCase();

    if (passDigited === passDigitedConfirm) {
      addToast({
        type: "warning",
        title: "Senha já utilizada",
        description:
          "A senha informada já foi utilizada anteriormente, favor inserir nova senha",
      });
      return;
    }

    if (passDigited !== memoryPass) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para continuar insira a senha atual certa e com as regras determinadas acimas",
      });
      return;
    }

    if (
      !isStrongPassword(props.password) ||
      !isStrongPassword(props.passwordConfirm)
    ) {
      setErrorPassword(true);
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para continuar insira a senha com a regra determinada acima",
      });
      return;
    }
    if (props.password !== props.passwordConfirm) {
      setErrorPassword(true);
      addToast({
        type: "warning",
        title: "Aviso",
        description: "As senhas não correspondem",
      });
      return;
    }
    dispatch(showLoading());

    try {
      const user = getValueFromlocalStorage("@Kenta:user");
      if (user) {
        const user_parsed = JSON.parse(user);
        const body = {
          id: user_parsed.id,
          name: user_parsed.name,
          email: user_parsed.email,
          password: sha1(props.passwordConfirm).toString().toUpperCase(),
          picture: user_parsed.picture,
          customerId: user_parsed.customerId,
          emailValidated: true,
          termAccepted: true,
          createIn: user_parsed.createIn,
          updateIn: user_parsed.updateIn,
          active: true,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        const { status, message } = await PasswordService.resetPasswordBody(
          body
        );

        if (!status) {
          setErrorPassword(true);
          addToast({
            type: "warning",
            title: message.includes("utilizada")
              ? "Senha já utilizada"
              : "Aviso",
            timeLimitToast: 5000,
            description: message.includes("utilizada")
              ? "Para continuar insira a senha com a regra determinada acima"
              : message,
          });
        } else {
          insertTolocalStorage(
            "@Kenta:password",
            sha1(props.passwordConfirm).toString().toUpperCase()
          );
          addToast({
            type: "success",
            title: "Sucesso",
            description: message,
          });
        }
      }
    } catch (err: any) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: err?.response?.data?.message,
      });
    }

    dispatch(hideLoading());
  }

  return (
    <Container>
      <ContainerTitle>
        <Typhography fontStyle="bold" size="very-bigger">
          Redefinir senha
        </Typhography>
      </ContainerTitle>
      <ContainerDescription>
        <Typhography fontStyle="regular" size="medium">
          Dica: utilize maiúsculas, minúsculas, números e caracteres especiais
          (@ & $ % #) para criar uma senha forte.
        </Typhography>
      </ContainerDescription>
      {props.isLoged ? (
        <InputAuth
          id="singIn_password"
          maxLength={200}
          width={380}
          error={errorPassword}
          label="Sua senha atual"
          disabled={loading}
          autoFocus={true}
          onChange={(passwordOld: string) => {
            props.setPasswordOld(passwordOld);
            if (errorPassword) {
              setErrorPassword(false);
            }
          }}
          value={props.passwordOld}
          setType={(type: typesInput) => setPasswordType(type)}
          icon={true}
          type={passwordType}
        />
      ) : null}
      <InputAuth
        id="new_password"
        maxLength={200}
        width={380}
        error={errorPassword}
        label="Digite a nova senha"
        disabled={loading}
        value={props.password}
        setType={(type: typesInput) => setPasswordType(type)}
        icon={true}
        type={passwordType}
        onChange={(password: string) => {
          props.setPassword(password);
          isStrongPassword(password);
          if (errorPassword) {
            setErrorPassword(false);
          }
        }}
      />
      <ContainerBar>
        <BarComponent strongLevel={strongLvl} />
      </ContainerBar>
      <InputAuth
        id="repeat_password"
        maxLength={200}
        width={380}
        error={errorPassword}
        label="Repita a nova senha"
        disabled={loading}
        value={props.passwordConfirm}
        setType={(type: typesInput) => setPasswordType(type)}
        icon={true}
        type={passwordType}
        onChange={(passwordConfirm: string) => {
          props.setPasswordConfirm(passwordConfirm);
          if (errorPassword) {
            setErrorPassword(false);
          }
        }}
      />
    </Container>
  );
};
