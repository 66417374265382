/* eslint-disable no-restricted-globals */
import styled from "styled-components";

interface ICircle {
  selected?: boolean;
}
interface IContainer {
  hasTranscription?: boolean;
}

export const Container = styled.div<IContainer>`
  height: ${(props) => (props.hasTranscription ? "61.9%" : "54%")};
  padding: ${(props) => (props.hasTranscription ? 0 : 24)}px;

  @media screen and (min-height: 848px) {
    height: ${(props) => (props.hasTranscription ? "67.5%" : "65%")};
  }

  @media screen and (min-height: 665px) and (max-height: 848px) {
    height: ${(props) => (props.hasTranscription ? "58%" : "65%")};
  }
`;

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  padding: 2px;
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 26px;

  align-items: center;

  cursor: pointer;
`;

export const Circle = styled.div<ICircle>`
  width: ${(props) => (props.selected ? "8px" : "20px")};
  height: ${(props) => (props.selected ? "8px" : "20px")};
  border-radius: 50%;
  border: ${(props) =>
    props.selected ? "8px solid #1D9BF0" : "2px solid #cbcbcb"};
  margin-right: 10px;

  background: #fff;
`;

export const ItemTitle = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const TimeContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const TimeTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #1a1a1a;
`;

export const Time = styled.strong`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1a1a1a;
`;

export const ContainerStatus = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export const StatusItem = styled.div`
  font-size: 18px;
  color: #7887a9;
  margin-top: 24px;
  font-family: 'Inter Tight', Arial, Helvetica, sans-serif;
`;
