import { IMenuList } from "../routers/interface";

export const menusListDefault: Array<IMenuList> = [
  {
    label: "DASHBOARD",
    rolesToShow: [""],
    show: true,
    location: "dashboard",
    index: 0,
    route: "/dashboard",
    locationNumber: 0,
  },
  {
    label: "PROCEDIMENTOS",
    rolesToShow: ["Administrador", "Operador"],
    show: true,
    location: "procedure",
    index: 1,
    route: "/dashboard/administracao/",
    locationNumber: 0,
  },
  {
    label: "ADMINISTRAÇÃO",
    rolesToShow: ["Master", "Administrador"],
    show: true,
    location: "administration",
    index: 2,
    route: "/dashboard/administracao",
    locationNumber: 0,
  },
  {
    label: "RELATÓRIOS",
    rolesToShow: ["Master", "Operador", "Administrador"],
    location: "report",
    show: true,
    index: 3,
    route: "dashboard/relatorio",
    locationNumber: 2,
  },
];

