import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface ILetterInterface {
  moreThanThreeLetters: boolean;
  selected?: boolean;
}

export const ContainerTabs = styled.div`
  height: 81.8vh;
  width: 35%;
  min-width: 360px;
  background: ${stylesGlobalDefault.white};
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  //padding: 0 20px;
`;

export const TabContainer = styled.div`
  width: 100%;
  min-width: 360px;
  height: 60px;
  margin: 0;
  padding: 0;
  //padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-shrink: 0;
`;

export const TabContainerTextAndLine = styled.div<ILetterInterface>`
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (!props.moreThanThreeLetters ? "50%" : "100%")};
  margin-bottom: 5px;
  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
      margin-bottom: 8px;
      span {
        margin-top: 6px;
      }
    `}

  &:hover {
    ${(props) =>
      props.selected === true
        ? css`
            border-bottom: 4px solid #e98d0b;
          `
        : css`
            border-bottom: 1px solid #e98d0b;
          `}
  }
`;

export const TabText = styled.span`
  text-align: center;
  /* 14 Semibold */
  font-family: ${stylesGlobalDefault.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;
